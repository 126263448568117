import { ApiPolling } from './types';

const JUMIO_EVENTS = {
  STARTED: 'workflow:start',
  SUCCESS: 'workflow:success',
  FAILED: 'workflow:failed'
} as const;

const DATA_CENTER = {
  SG: 'sgp',
  US: 'us'
} as const;

const API_POLLING: ApiPolling = {
  maxRetry: 3,
  delayInMS: 100,
  pollingInterval: 5 * 1000 // in MS
};

const API_RETRY = {
  maxRetry: 3,
  delayInMS: 100
};

enum JumioeKYCStatus {
  CREATED = 'CREATED',
  PROCESSED = 'PROCESSED'
}

const API_POLLING_TIME = 5 * 60 * 1000; // in MS

const delay = (time: number) =>
  new Promise((resolve) => setTimeout(resolve, time));

const retry = async (
  config: {
    maxTries?: number;
    delayInMS?: number;
    shouldRetry?: (e: Error) => boolean;
  },
  asyncFunction: (...params: any) => any,
  ...params: any
) => {
  const {
    maxTries = API_RETRY.maxRetry,
    delayInMS = API_RETRY.delayInMS,
    shouldRetry = () => true
  } = config;
  let attempts = 0;
  let hasSuccess = false;
  while (!hasSuccess) {
    try {
      attempts++;
      hasSuccess = true;
      return await asyncFunction(...(params || []));
    } catch (error: any) {
      hasSuccess = false;
      if (attempts === maxTries || !shouldRetry(error as Error)) {
        throw error;
      }
      await delay(delayInMS);
    }
  }
};

export { retry };
export { JumioeKYCStatus };
export { JUMIO_EVENTS, DATA_CENTER, API_POLLING, API_POLLING_TIME };
